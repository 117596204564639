.authClients {
  padding: 0 24px;
  overflow: hidden;

  .authClientBtn {
    background-color: #4abd7f !important;
    border-color: #4abd7f !important;
    font-weight: 600;
  }

  .iconBtn {
    margin-right: 4px;
  }
}
