/* Add global styles here */

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.modal-form-control {
  max-height: 300px;
  overflow: auto;
  background-color: #F2F2F2;
  border-radius: 15px;
  padding: 20px;

  &::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
    background-color: transparent; /* Make scrollbar background transparent */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Make scrollbar track transparent */
  }
}

.modal {
  top: 48px !important;

  &-content {
    border-radius: 15px;
    padding: 10px;
  }
}
