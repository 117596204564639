.row:last-child {
  position: sticky;
  right: 0;
  top: 0;
  left: 0;
  width: 100px;
  background-color: #daf8e8;
  min-width: unset;
}

.header:last-child {
  width: 100px;
  min-width: unset;
}

.actionSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  .searchInput {
    width: 320px;
    border-radius: 12px;
  }
}

.header {
  user-select: none;

  .sortIndicatorHidden {
    display: none;
  }

  .sortIndicator {
    display: none;
  }

  &:hover .sortIndicator {
    opacity: 60%;
    display: inline;
  }
}

.adminTableContainer {
  overflow-x: scroll;
  padding-bottom: 11px;

  table {
    border-collapse: collapse;
    font-size: 14px;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  // header
  thead {
    tr {
      background-color: #4bbd7f;
      color: #ffffff;
      text-align: left;
    }

    th {
      padding: 8px 16px;
      white-space: nowrap;
    }
  }

  // rows
  tbody {
    td {
      height: 58px;
      min-width: 180px;
      padding: 10px 16px;
    }

    tr {
      border-bottom: 1px solid #dddddd;
    }

    tr:last-child {
      border-bottom: 2px solid #4bbd7f;
    }

    tr:nth-child(even) {
      background-color: #f8f8f8;
    }
  }
}