.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 54px;
  padding: 8px 20px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 9999;
}

.logoAndNavigation {
  display: flex;
  gap: 20px;
}

.links {
  display: flex;
  gap: 12px;

  a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 500;
  }

  a:hover {
    color: teal;
  }
}

