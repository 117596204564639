.editCell {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.editCell button {
  all: unset;
  font-size: 1.2rem;
  color: #00234b;
}

.editCell button:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

.editCell button[name="done"] {
  color: #4bbd7f;
}

.editCell button[name="cancel"] {
  color: crimson;
}