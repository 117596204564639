.imageCardContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cardContent {
    display: flex;
    flex-direction: column;
}

.badgeContainer {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.badge {
    width: min-content;
    padding: 4px 8px !important;
}

.imageCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 0.5rem 1rem;
    background: #f9f9f9;

    .imageThumbnail {
        width: 45px;
        height: 45px;
        background-size: cover;
        background-position: center;
        position: relative;
        cursor: pointer;
        margin-right: 1rem;

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease;

            i {
                font-size: 1rem;
            }
        }

        &:hover .overlay {
            opacity: 1;
        }
    }

    .imageTitle {
        font-size: 12px;
        font-weight: 500;
        word-break: break-word;
        margin: 0;
        padding: 0;
        flex-grow: 1;
        text-align: left;
        color: #00000090;
    }

    button {
        margin-left: auto;
    }
}

.customModalTop {
    background: #00000080;

    // @TODO: the classes are not getting applied here. Recheck needed
    .modal-dialog-centered {
        width: 70% !important;
        transform: translateY(-10%) !important;

        @media (max-width: 768px) {
            bottom: 28% !important;
            max-width: 100% !important;
        }
    }
}

.previewImage {
    width: 100%;
    height: auto;
    max-height: 90vh;
    object-fit: contain;
    margin: auto;
}