.cellValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textInput {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}