.pagination {
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 4px;

  button {
    border-radius: 12px;
    background-color: #4abd7f !important;
    border-color: #4abd7f !important;
  }
}

.paginationIndex {
  display: flex;
  align-items: center;
  padding: 0 4px;
}