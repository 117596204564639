.uploadContainer {
    border: 1px dashed #ccc;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconAndText {
    display: flex;
    align-items: center;
}

.iconAndText div {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.uploadField .text-muted {
    font-size: 12px;
    margin: 0;
}

.uploadField .image-text {
    font-size: 12px;
    margin: 0;
}
